import { HttpStatusCode } from "axios";

import { AuthToken, LoggedUser } from "../models";
import { odin_api } from "../services/odin";

export const signIn = async (username: string, password: string): Promise<AuthToken> => {
  const params = new FormData();
  params.append("username", username);
  params.append("password", password);

  const response = await odin_api.post("auth", params, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });

  if (response.status !== HttpStatusCode.Ok) {
    throw new Error();
  }

  return response.data;
};

export const getUser = async (token: string): Promise<LoggedUser> => {
  const response = await odin_api.get("my/user", { headers: { Authorization: `Bearer ${token}` } });
  if (response.status !== HttpStatusCode.Ok) {
    throw new Error();
  }
  return response.data;
};
