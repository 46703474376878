import axios, { AxiosResponse, Method } from "axios";

const odin_api = axios.create({
  baseURL: process.env.REACT_APP_ODIN_API_URL,
});

export const sendRequest = async (
  method: Method,
  uri: string,
  data: object | undefined = undefined
): Promise<AxiosResponse> => {
  const response = await odin_api(uri, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  });

  return response;
};

export { odin_api };
