import React, { useContext, useState, useEffect } from 'react';

import {
    TextField,
    Button,
    Card,
    CardContent,
    FormControl
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../contexts';

import { FullScreenLoading } from '../../components';

import './index.css';

export const Login = () => {
    const { authenticated, handleLogin } = useContext(AuthContext);
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);

    useEffect(() => {
        if (authenticated) {
            navigate('/')
        }
    }, [authenticated]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setActiveFullScreenLoading(true);

        await handleLogin(username, password)

        setActiveFullScreenLoading(false);
    }

    return (
        <>
            <Card
                id="login"
                sx={{
                    minWidth: 275,
                    maxWidth: 600,
                    margin: 'auto',
                    marginTop: '10%',
                    padding: '20px',
                    backgroundColor: '#fff'
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <div className='headerLogin'>
                        <img src="https://static.wixstatic.com/media/7cd449_4f83308cd7594c0f8958136504fb9b25~mv2.png/v1/crop/x_0,y_0,w_423,h_138/fill/w_178,h_58,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo-biso_PM_tagline_edited.png" alt="Logo Biso" />
                        <p>Backoffice</p>
                    </div>
                    <form className="boxModel formLogin" onSubmit={handleSubmit} >
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                required
                                id="username"
                                label="Username"
                                type='text'
                                variant="outlined"
                                value={username}
                                onChange={(event) => setUsername(event.target.value)}
                            />
                        </FormControl>
                        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                            <TextField
                                required
                                id="password"
                                label="Senha"
                                type='password'
                                variant="outlined"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                        </FormControl>
                        <div className="actions">
                            <Button variant="contained" type="submit">Entrar</Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
            <FullScreenLoading open={activeFullScreenLoading} />
        </>
    );
}