class UserContactInfoEntity {
  public email: string = "";
  public first_name: string = "";
  public last_name: string = "";

  constructor({ email, first_name, last_name }) {
    this.email = email;
    this.first_name = first_name;
    this.last_name = last_name;
  }
}

export class RegisterUserEntity {
  public name: string = "";
  public email: string = "";
  public username: string = "";
  public password: string = "";
  public role: string = "";
  public contact_info: UserContactInfoEntity = new UserContactInfoEntity({
    email: "",
    first_name: "",
    last_name: "",
  });

  constructor({ firstName, lastName, email, password, role }) {
    this.username = email;
    this.password = password;
    this.role = role;
    this.contact_info = new UserContactInfoEntity({
      email: email,
      first_name: firstName,
      last_name: lastName,
    });
  }
}
