import { codes } from 'currency-codes';

export const CURRENCY_CODE = codes();

type PreferenceOption = {
    label: string;
    value: string;
}


export const locales: PreferenceOption[] = [
    {
        label: "Português",
        value: "pt_br"
    },
    {
        label: "Inglês",
        value: "en_us"
    },
    {
        label: "Espanhol",
        value: "es"
    }
]

export const currencies: PreferenceOption[] = CURRENCY_CODE.map(currency => {
    return {
        label: currency,
        value: currency
    }
})

export const timezones: PreferenceOption[] = [
    {
        value: 'Atlantic/Azores',
        label: 'GMT-1 (Atlantic/Azores)'
      },
      {
        value: 'Atlantic/South_Georgia',
        label: 'GMT-2 (Atlantic/South_Georgia)'
      },
      {
        value: 'America/Sao_Paulo',
        label: 'GMT-3 (America/Sao_Paulo)'
      },
      {
        value: 'America/La_Paz',
        label: 'GMT-4 (America/La_Paz)'
      },
      {
        value: 'America/New_York',
        label: 'GMT-5 (America/New_York)'
      },
      {
        value: 'America/Mexico_City',
        label: 'GMT-6 (America/Mexico_City)'
      },
      {
        value: 'America/Phoenix',
        label: 'GMT-7 (America/Phoenix)'
      },
      {
        value: 'America/Anchorage',
        label: 'GMT-8 (America/Anchorage)'
      },
      {
        value: 'Pacific/Gambier',
        label: 'GMT-9 (Pacific/Gambier)'
      },
      {
        value: 'Pacific/Tahiti',
        label: 'GMT-10 (Pacific/Tahiti)'
      },
      {
        value: 'Pacific/Midway',
        label: 'GMT-11 (Pacific/Midway)'
      },
      {
        value: 'Pacific/Wallis',
        label: 'GMT-12 (Pacific/Wallis)'
      },
      {
        value: 'Europe/London',
        label: 'GMT+0 (Europe/London)'
      },
      {
        value: 'Europe/Paris',
        label: 'GMT+1 (Europe/Paris)'
      },
      {
        value: 'Africa/Cairo',
        label: 'GMT+2 (Africa/Cairo)'
      },
      {
        value: 'Asia/Istanbul',
        label: 'GMT+3 (Asia/Istanbul)'
      },
      {
        value: 'Asia/Dubai',
        label: 'GMT+4 (Asia/Dubai)'
      },
      {
        value: 'Asia/Kolkata',
        label: 'GMT+5 (Asia/Kolkata)'
      },
      {
        value: 'Asia/Almaty',
        label: 'GMT+6 (Asia/Almaty)'
      },
      {
        value: 'Asia/Bangkok',
        label: 'GMT+7 (Asia/Bangkok)'
      },
      {
        value: 'Asia/Hong_Kong',
        label: 'GMT+8 (Asia/Hong_Kong)'
      },
      {
        value: 'Asia/Tokyo',
        label: 'GMT+9 (Asia/Tokyo)'
      },
      {
        value: 'Pacific/Honolulu',
        label: 'GMT+10 (Pacific/Honolulu)'
      },
      {
        value: 'Pacific/Apia',
        label: 'GMT+11 (Pacific/Apia)'
      },
      {
        value: 'Pacific/Fiji',
        label: 'GMT+12 (Pacific/Fiji)'
      }
]