import React, { useState } from 'react';

import dayjs from 'dayjs';

import { useNavigate } from "react-router-dom";

import { 
    Button, 
    FormControl, 
    TextField, 
    InputLabel,
    Select,
    MenuItem 
} from '@mui/material';

import { DateField, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Container, FullScreenLoading } from "../../components";

import { 
    RegisterCompanyEntity, 
    locales,
    currencies,
    timezones  
} from '../../models';

import { toast } from 'react-toastify';

import { registerCompany } from '../../adapters';

import './index.css';

export const RegisterCompany = () => {
    const navigate = useNavigate();

    const [companyOwnerFirstName, setCompanyOwnerFirstName] = useState('');
    const [companyOwnerLastName, setCompanyOwnerLastName] = useState('');
    const [companyOwnerEmail, setCompanyOwnerEmail] = useState('')
    const [companyName, setCompanyName] = useState('');
    const [companyUserName, setCompanyUserName] = useState('');
    const [retetionPeriodData, setRetetionPeriodData] = useState(dayjs(Date.now()));
    const [companyPreferenceTimezone, setCompanyPreferenceTimezone] = useState('');
    const [companyPreferenceCurrency, setCompanyPreferenceCurrency] = useState('');
    const [companyPreferenceLocale, setCompanyPreferenceLocale] = useState('');
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setActiveFullScreenLoading(true);

        try {
            const companyData = new RegisterCompanyEntity({
                companyOwnerFirstName,
                companyOwnerLastName,
                companyOwnerEmail,
                companyName,
                companyUserName,
                retetionPeriodData: retetionPeriodData.format('YYYY-MM-DD'),
                companyPreferenceTimezone,
                companyPreferenceCurrency,
                companyPreferenceLocale
            })

            await registerCompany(companyData);

            toast.success('Empresa cadastrada com sucesso!');

            navigate('/');
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    }

    return (
        <Container>
            <form className="boxModel formRegisterCompany" onSubmit={handleSubmit} >
                <h2 className='titleRegisterCompany'>Cadastro de empresa</h2>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="firstName" 
                        label="Nome do responsável"
                        type='text'
                        variant="outlined" 
                        value={companyOwnerFirstName}
                        onChange={(event) => setCompanyOwnerFirstName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="lastName" 
                        label="Sobrenome do responsável"
                        type='text'
                        variant="outlined" 
                        value={companyOwnerLastName}
                        onChange={(event) => setCompanyOwnerLastName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="email" 
                        label="Email do responsável"
                        type='email'
                        variant="outlined" 
                        value={companyOwnerEmail}
                        onChange={(event) => setCompanyOwnerEmail(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="companyName" 
                        label="Nome da empresa"
                        type='text'
                        variant="outlined" 
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="companyName" 
                        label="Login da empresa"
                        type='text'
                        variant="outlined" 
                        value={companyUserName}
                        onChange={(event) => setCompanyUserName(event.target.value.replace(/\s/g, '').toLowerCase())}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateField
                            required
                            format='DD/MM/YYYY'
                            label="Período de retenção de dados" 
                            value={retetionPeriodData}
                            onChange={(newValue) => setRetetionPeriodData(dayjs(newValue))}
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="selectTimezoneLabel">Fuso horário</InputLabel>
                    <Select
                        required
                        labelId="selectTimezoneLabel"
                        id="selectTimezone"
                        value={companyPreferenceTimezone}
                        label="Fuso horário"
                        onChange={event => setCompanyPreferenceTimezone(event.target.value)}
                    >
                        {timezones.map((timezone, index) => (
                            <MenuItem key={index} value={timezone.value}>{timezone.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="selectCurrencyLabel">Moeda</InputLabel>
                    <Select
                        required
                        labelId="selectCurrencyLabel"
                        id="selectCurrency"
                        value={companyPreferenceCurrency}
                        label="Moeda"
                        onChange={event => setCompanyPreferenceCurrency(event.target.value)}
                    >
                        {currencies.map((currency, index) => (
                            <MenuItem key={index} value={currency.value}>{currency.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="selectLocaleLabel">Idioma</InputLabel>
                    <Select
                        required
                        labelId="selectLocaleLabel"
                        id="selectLocale"
                        value={companyPreferenceLocale}
                        label="Idioma"
                        onChange={event => setCompanyPreferenceLocale(event.target.value)}
                    >
                        {locales.map((locale, index) => (
                            <MenuItem key={index} value={locale.value}>{locale.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="actions">
                    <Button variant="contained" type="submit">Cadastrar</Button>
                </div> 
            </form>
            <FullScreenLoading open={activeFullScreenLoading} />
        </Container>
    )
}